import * as React from 'react';
import { useState, useEffect } from 'react';
import { bdAxios } from '../components/utils/Axios';
import { parseJwt, getToken } from '../components/utils/Common';

function AdminRatio()
{

	const [tab, setTab] = useState<string>('access');
	const [message, setMessage] = useState<string>();
	const [yearsAccess, setYearsAccess] = useState<Array<any>>([]);
	const [cumul, setCumul] = useState<Array<any>>([]);
	const [yearly, setYearly] = useState<Array<any>>([]);

	// const yearStart: number = 2019;
	// const yearEnd: number = (new Date().getFullYear())+3;
	// const yearDiff = yearEnd - yearStart;
	// const years: any= Array.from({ length: yearDiff }, (_, i) => yearStart+i);

	useEffect(() => {
		let token = (getToken() ? getToken() : '');
		if (token === null) {
			return;
		}
		bdAxios.get('ratios/configs', {headers:{
			'Authorization': "Bearer " + token
		}}).then((r:any) => {
			let acc: any = r.data.configs.form_access;
			let lastAccessYear: any = 0;
			acc.map((yearAccess: any) => {
				if (parseInt(yearAccess.year) > lastAccessYear) {
					lastAccessYear = parseInt(yearAccess.year);
				}
			});

			// const maxYear = new Date().getFullYear() + 3)
			const accYears: any= Array.from({ length: 3 }, (_, i) => (parseInt(lastAccessYear)+i+1));
			accYears.map((y: any) => {
				acc.push({
					"end_date": null,
					"start_date": null,
					"opened": false,
					"year": y
				})
			})
			setYearsAccess(acc);

			let cu: any = r.data.configs.cumul_access;
			lastAccessYear = 0;
			cu.map((yearCumul: any) => {
				if (parseInt(yearCumul.year) > lastAccessYear) {
					lastAccessYear = parseInt(yearCumul.year);
				}
			});
			const cumulYears: any= Array.from({ length: 3 }, (_, i) => (parseInt(lastAccessYear)+i+1));

			cumulYears.map((y: any) => {
				cu.push({
					"opened": false,
					"year": y
				})
			})

			setCumul(cu);

			let yly: any = r.data.configs.annual_values;
			lastAccessYear = 0;
			yly.map((yearYearly: any) => {
				if (parseInt(yearYearly.year) > lastAccessYear) {
					lastAccessYear = parseInt(yearYearly.year);
				}
			});
			const yearlyYears: any= Array.from({ length: 3 }, (_, i) => (parseInt(lastAccessYear)+i+1));
			yearlyYears.map((y: any) => {
				yly.push({
					"retainer": "",
					"year": y
				})
			})
			setYearly(yly);
		});
	}, []);
	const submit = () => {
		let token = (getToken() ? getToken() : '');
		if (token === null) {
			return;
		}

		const form : any = document.querySelector("#ratio-configs");
		const formData : FormData = new FormData(form);
		bdAxios.post('update/configs', formData, {
			headers: {
				'Authorization': "Bearer " + token
			}
		}).then((r) => {

			setMessage('Configurations mises à jour avec succès.');
		})
		setTimeout(() => {
			setMessage('');
		}, 3000)
	}
	return (
		<div className="ratio-admin-wrpr">
			<h2>Administration</h2>
		
			<form id="ratio-configs">

				{
					message && message !== '' ?
						<div className="transition-opacity p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800" role="alert">
						  <span className="font-medium">{message}</span>
						</div>
					: void(0)
				}
					<div className="formRow nav">
						<div className={tab === 'access' ? 'active' : ''} onClick={e => setTab('access')}>Accès aux formulaires</div>
						<div className={tab === 'cumul' ? 'active' : ''} onClick={e => setTab('cumul')}>Accès au cumulatif d'ensemble</div>
						<div className={tab === 'yearly' ? 'active' : ''} onClick={e => setTab('yearly')}>Valeur annuelles</div>
					</div>
					{
						tab === "access" ?
						<fieldset>
							<table>
								<thead>
									<tr>
										<th>Année</th>
										<th>Ouvert?</th>
										<th>Date de début</th>
										<th>Date de fin</th>
									</tr>
								</thead>
								<tbody>
									{
										yearsAccess && yearsAccess.length > 0 ?
											yearsAccess.map((year:any) => {
												return (
													<tr key={year.year}>
														<td>{year.year}</td>
														<td>
															<input
																type="checkbox"
																defaultChecked={year.opened}
																value="1"
																name={'acc_year['+year.year+'][state]'}
															/>
														</td>
														<td>
															<input type="date"
																className="small"
																defaultValue={year.start_date}
																name={'acc_year['+year.year+'][start]'}
															/>
														</td>
														<td>
															<input type="date"
																className="small"
																defaultValue={year.end_date}
																name={'acc_year['+year.year+'][end]'}
															/>
														</td>
													</tr>
												)
											})
										: void(0)
									}
								</tbody>
							</table>
						</fieldset>
						: void(0)
					}

					{
						tab === "cumul" ?
							<fieldset>
								<table>
									<thead>
										<tr>
											<th>Année</th>
											<th>Ouvert?</th>
										</tr>
									</thead>
									<tbody>
									{
										cumul && cumul.length > 0 ?
											cumul.map((year:any) => {
												return (
													<tr key={year.year} className="centered-data">
														<td>{year.year}</td>
														<td>
															<input
																type="checkbox"
																defaultChecked={year?.opened}
																value="1"
																name={'cumul_year['+year.year+'][state]'}
															/>
														</td>
													</tr>
												)
											})
										: void(0)
									}
								    </tbody>
								</table>
							</fieldset>
						: void(0)
					}

					{
						tab === 'yearly' ?
							<fieldset>
								<table>
									<thead>
										<tr>
											<th>Année</th>
											<th>Prélèvements (P)</th>
										</tr>
									</thead>
									<tbody>
									{
										yearly.map((year:any) => {
											return (
												<tr key={year.year} className="centered-data">
													<td>{year.year}</td>
													<td className="row">
														<input name={'prelev['+year.year+'][retainer]'} type="number" step="0.01" defaultValue={year.retainer} />
													</td>
												</tr>
											)
										})
									}
								    </tbody>
								</table>
							</fieldset>

						: void(0)
					}
			</form>
			<div className="btnrow">
				<div onClick={submit} className="button">Enregistrer</div>
			</div>
		</div>
	)
}

export default AdminRatio;