import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faBriefcase, faUser, faScaleBalanced, faFolder, faCartFlatbed, faCircleQuestion, faComputer, faHouse, faPenRuler, faPhone, faStar, faUsersLine } from "@fortawesome/free-solid-svg-icons";
import { RouteObject } from "react-router-dom";
import Accueil from "../../pages/Accueil";
import Connexion from "../../pages/Connexion";
import Administration from "../../pages/Administration";
import Offices from "../../pages/Offices";
import OffresEmplois from "../../pages/OffresEmplois";
import Employees from "../../pages/Employees";
import Employee from "../../pages/Employee";
import AddEmployee from "../../pages/AddEmployee";
import EditOfficePage from "../../pages/EditOfficePage";
import EditRatioPage from "../../pages/EditRatioPage";
import AddOffice from "../../pages/AddOffice";
import RatioForm from "../../pages/forms/RatioForm";
import Ratios from "../../pages/Ratios";
import Ratio from "../../pages/Ratio";
import { baseUrlForEnvironment } from "./Axios";
import { parseJwt, getToken } from './Common';

interface RouteData {
	icon?: IconDefinition;
	key: string;
	path: string;
    title?: string;
    element: any;
    order?: number;
    children?: RouteObject[],
}

export const routes: RouteData[] = [
    {
        "order": -1,
        "key": "connexion",
        "path": "/connexion",
        "element": <Connexion/>
    },
    {
        "order": 2,
        "key": "offices",
        "title": "Études",
        "icon": faBriefcase,
        "path": "/etudes",
        "element": <Offices/>
    },
    {
        "order": 5,
        "key": "ratios",
        "title": "Ratios",
        "icon": faScaleBalanced,
        "path": "/ratios",
        "element": <Ratios/>
    },
    {
        "order": 5,
        "key": "ratio",
        "title": "Ratio",
        "icon": faScaleBalanced,
        "path": "/ratios/:id",
        "element": <Ratio/>
    },
    {
        "order": 3,
        "key": "employees",
        "title": "Employés",
        "icon": faUser,
        "path": "/employes",
        "element": <Employees/>
    },
    {
        'order': 9999,
        "key": "employee",
        "title": "Employé",
        "path": "/employes/:id",
        "element": <Employee/>
    },
    {
        'order': 9999,
        "key": "single-ratio",
        "title": "Ratio",
        "path": "/ratio-form/:id",
        "element": <EditRatioPage />
    },
    {
        'order': 9999,
        "key": "addemployee",
        "title": "Ajouter un employé",
        "path": "/employes/ajouter",
        "element": <AddEmployee/>
    },
    {
        'order': 9999,
        "key": "addoffice",
        "title": "Ajouter un bureau",
        "path": "/etudes/ajouter",
        "element": <AddOffice/>
    },
    {
        'order': 9999,
        "key": "addoffice",
        "title": "Ajouter un bureau",
        "path": "/etudes/:id",
        "element": <EditOfficePage/>
    },
    {
        "order": 4,
        "key": "jobs",
        "title": "Offres d'emploi",
        "icon": faFolder,
        "path": "/offres-emplois",
        "element": <OffresEmplois/>
    },
    {
        "order": 1,
        "icon": faHouse,
        "key": "accueil",
        "path": "/",
        "title": "Accueil",
        "element": <Accueil/>
    },
];


