import * as React from 'react';
import { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import EditOffice from './forms/EditOffice';
import { useLocation, useParams } from "react-router-dom";
import { bdAxios } from '../components/utils/Axios';
import { parseJwt, getToken } from '../components/utils/Common';

function EditOfficePage() {
	const location:any = useLocation();
	const params:any = useParams();

	const [office, setOffice] = useState<any>();
	useEffect(() => {
		let token = (getToken() ? getToken() : '');
		if (token === null) {
			return;
		}
		if (location?.state?.office && Date.now() - location?.state?.office?.lastMod < 1000) {
			setOffice(location?.state?.office);
		} else {
			bdAxios.get('myoffices', {headers:{
				'Authorization': "Bearer " + token
			}}).then((r:any) => {
				let soffice:any = null;
				r.data.offices.map((off: any) => {
					if (off.ID == params.id) {
						soffice = off;
					}
				});
				setOffice(soffice);
			});
		}
	}, []);

	return (
		<Layout title="Étude" classes="accueil-page">
			<div className="inner-page">
				<div className="content">
				{ office ?
					<EditOffice office={office} mode="add"/>
					: void(0)
				}
				</div>
			</div>
		</Layout>

	)
}
export default EditOfficePage;
