import * as React from 'react';
import { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import RatioForm from './forms/RatioForm';
import { useLocation, useParams } from "react-router-dom";
import { bdAxios } from '../components/utils/Axios';
import { parseJwt, getToken } from '../components/utils/Common';

function EditRatioPage() {
	const location:any = useLocation();
	const params:any = useParams();

	const [ratio, setRatio] = useState<any>();
	useEffect(() => {
		let token = (getToken() ? getToken() : '');
		if (token === null) {
			return;
		}

		bdAxios.get('ratios/get?id='+params.id, {headers:{
			'Authorization': "Bearer " + token
		}}).then((r:any) => {
			let ratioData = JSON.parse(r.data.data.ratio.data);
			r.data.data.ratio.data = ratioData;
			setRatio(r.data.data.ratio);

		});

	}, []);

	return (
		<Layout title="Employé" classes="accueil-page">
			<div className="inner-page">
				<div className="content">
				{ ratio ?
					<RatioForm selectedRatio={ratio} mode="edit"/>
					: void(0)
				}
				</div>
			</div>
		</Layout>

	)
}
export default EditRatioPage;
