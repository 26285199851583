import * as React from 'react';
import { useState } from 'react';
import Layout from '../components/Layout';
import Loading from '../components/Loading';

function Accueil() {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Layout title="Accueil" classes="accueil-page">
        {
          loading ? <Loading />
        : 
          <>
            <div className="inner-page">
              <h1 className="title">Base de données</h1>
            </div>
          </>
        }
    </Layout>
  );
}

export default Accueil;
