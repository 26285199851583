import * as React from "react";
import { useState, useEffect } from "react";
import { bdAxios } from "../../components/utils/Axios";
import { getToken } from "../../components/utils/Common";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faX } from '@fortawesome/free-solid-svg-icons';


const EmployeeForm = (data: any) => {
  const navigate = useNavigate();

  const [offices, setOffices] = useState<any>();
  const [functionsOptions, setFunctionsOptions] = useState<any>();
  const [employeeFunctions, setEmployeeFunctions] = useState<any>();
  const [employeeDesc, setEmployeeDesc] = useState<any>();
  const [employeeOffice, setEmployeeOffice] = useState<any>();
  const [employeeParentOffice, setEmployeeParentOffice] = useState<any>();
  const [isNotary, setIsNotary] = useState<boolean>(false);
  const [filePreview, setFilePreview] = useState<any>();
  const [networkList, setNetworkList] = useState<any>();
  const [committeeOptions, setCommitteeOptions] = useState<Array<object>>();
  const [v, setV] = useState(0);
  const employee: any = data.employee;
  
  const setMessage: any = data.setMessage;
  const [selectedEmployee, setSelectedEmployee] = useState<any>();
  const [selectedPhoto, setSelectedPhoto] = useState<any>();
  const [showPhotoPreview, setShowPhotoPreview] = useState<Boolean>(!!employee?.image);
  const [photoPreviewErrorMessage, setPhotoPreviewErrorMessage] = useState<string>('');


  useEffect(() => {
    getCommitteeOptions();
  }, []);

  const getCommitteeOptions = async() => {
    let token = getToken();
    if (!token) {
      return;
    }
    const response = await bdAxios.get("/committees", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    const data = await response.data;
    if (!data){
      return
    }
    
    setCommitteeOptions(data?.committees);

    
  }

  const genderOptions: any = [
    { value: "M", label: "Masculin" },
    { value: "F", label: "Féminin" },
    { value: "O", label: "Autre" },
  ];

  const getEmployeeCommittees = () => {
    const committees: any = [];
    if (employee?.fields?.committees) {
      employee?.fields?.committees.map((committee: any) => {
        committees.push({
          value: committee.ID,
          label: committee.post_title,
        });
      });
    }
    return committees;
  };

  const getDateFieldType = () => {
    return "date";
  };

  const getIsAssociate = () => {
    return employee?.fields?.is_associate ? true : false;
  };

  const getIsCAMember = () => {
    return employee?.fields?.is_ca_member ? true : false;
  };

  const getEmployeeGender = () => {
    const gender: any = [];
    employee?.fields?.titles_gender == "F"
      ? gender.push({ value: "F", label: "Féminin" })
      : gender.push({ value: "M", label: "Masculin" });

    return gender;
  };

  useEffect(() => {
    setEmployeeDesc(employee?.post_content);
    setEmployeeOffice(parseInt(employee?.fields.branch?.ID));

    setNetworkList(employee?.fields.social_network_list);

    const empFunctions: any = [];
    if (employee?.terms) {
      const termKeys: any = Object.keys(employee.terms);
      for (const t in employee?.terms) {
        empFunctions.push({ label: employee.terms[t], value: t });
        if (
          employee.terms[t].toLowerCase() === "notaire" ||
          employee.terms[t].toLowerCase() === "avocat"
        ) {
          setIsNotary(true);
        }
      }
    } else if (employee?.tags) {
      for (const t in employee?.tags) {
        empFunctions.push({ label: employee.tags[t].name, value: t });
        if (
          employee.terms[t].toLowerCase() === "notaire" ||
          employee.terms[t].toLowerCase() === "avocat"
        ) {
          setIsNotary(true);
        }
      }
    }
    setEmployeeFunctions(empFunctions);
    let token = getToken() ? getToken() : "";
    if (token === null) {
      return;
    }

    bdAxios
      .get("myoffices?withfunctions=1", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((r) => {
        setOffices(r.data.offices);
        const fctOptions: Array<any> = [
          { label: "Sélectionner une ou plusieurs fonctions", value: null },
        ];
        r.data.fcts.map((func: any) => {
          fctOptions.push({ label: func.label, value: func.id });
        });

        setFunctionsOptions(fctOptions);
        // (r.data.offices);
      });
  }, []);

  useEffect(() => {
    getEmployeeParentOffice(offices, employeeOffice);
  }, [employeeOffice, employee, offices]);

  const addSocialNetwork = () => {
    let sNetworks: any = networkList;
    if (!sNetworks) {
      sNetworks = [];
    }

    sNetworks.push({ title: "", url: "" });
    setNetworkList(sNetworks);
    setV((v) => v + 1);
  };

  const getOfficeOptions = () => {
    if (!offices) {
      return [{ label: "Sélectionner", value: null }];
    }

    const options: Array<any> = [{ label: "Sélectionner", value: null }];
    const tree = new Map();

    offices.sort((a: any, b: any) => {
      return a.post_title.localeCompare(b.post_title);
    });

    offices.map((office: any) => {
      if (office.post_parent == "0" || !office.post_parent) {
        tree.set(office.ID, { parent: office, children: [] });
      }
    });

    offices.map((office: any) => {
      if (office.post_parent != "0" || !!office.post_parent) {
        const v = tree.get(office.post_parent);
        v.children.push(office);
        tree.set(office.post_parent, v);
      }
    });

    tree.forEach((group: any) => {
      let g = {
        label: group.parent.post_title,
        options: group.parent.post_parent != 0 ? [{ label: group.parent.post_title, value: group.parent.ID }] : [],
      };
      group.children.map((child: any) => {
        g.options.push({ label: "  " + child.post_title, value: child.ID });
      });

      options.push(g);
    });

    return options;
  };

  const getEmployeeOffice = () => {
    const opt: any = [];
    if (employee && employee?.fields.branch) {
      opt.push({
        label: employee.fields.branch.post_title,
        value: parseInt(employee.fields.branch.ID),
      });
    }

    return opt;
  };

  const getEmployeeParentOffice = (officeList: any, employeeOffice: number) => {
    const employeeOfficeObj = officeList?.filter(
      (office: any) => office.ID === employeeOffice
    );
    if (employeeOfficeObj && employeeOfficeObj[0]?.post_parent !== 0) {
      const parentOffice = officeList?.filter(
        (office: any) => office.ID === employeeOfficeObj[0]?.post_parent
      );
      setEmployeeParentOffice(parentOffice);
    } else {
      setEmployeeParentOffice(null);
    }
  };

  const getEmployeeDesc = () => {
    if (employee) {
      return employee?.post_content;
    }

    return "";
  };

  const submitEmployee = (formData: any) => {
    let token = getToken() ? getToken() : "";
    if (token === null) {
      return;
    }

    if (employee && data.mode === "edit") {
      // update
      formData.append("id", employee?.ID);
      bdAxios
        .post("update/employee", formData, {
          headers: {
            authorization: "Bearer " + token,
          },
        })
        .then((r) => {
          navigate("/employes", {
            state: { message: "Employé.e mis.e à jour avec succès." },
          });
          // setSelectedEmployee(null);
          // setMessage('Employé mis à jour avec succès.');
        })
        .catch((error) => { 
          console.error(error);
         });
    } else {
      bdAxios
        .post("create/employee", formData, {
          headers: {
            authorization: "Bearer " + token,
          },
        })
        .then((r) => {
          navigate("/employes", {
            state: { message: "Employé.e créé.e avec succès." },
          });
        })
        .catch((error) => { 
          console.error(error);
        });
    }
  };
  const submit = () => {
    let token = getToken() ? getToken() : "";
    if (token === null) {
      return;
    }

    const form: any = document.querySelector("#employee-form");
    const fcts: any = form.querySelectorAll("[name^=fcts]");
    let isValid = true;

    fcts.forEach((fct: any) => {
      if (fct.value == "") {
        isValid = false;
        const element: any = document.querySelector(".fct-select");
        element.style.border = "1px solid red";
      }
    });

    form.querySelectorAll("[required]").forEach((field: any) => {
      if (field.value === "") {
        field.setCustomValidity("Requis ou invalide");
        field.classList.add("invalid");
        isValid = false;
      } else {
        field.classList.remove("invalid");
      }
    });

    let nameField: any = document.querySelector("[name=office]");
    if (nameField.value === null || nameField.value === "") {
      isValid = false;
      const selectWrapper: any = document.querySelector(".officeSelect");
      selectWrapper.classList.add("invalid");
    } else {
      const selectWrapper: any = document.querySelector(".officeSelect");
      selectWrapper.classList.remove("invalid");
    }

    if (!isValid) {
      window.scrollTo(0, 0);
      return false;
    }
    const formData = new FormData(form);
    formData.append("post_content", employeeDesc);
    if (employeeParentOffice) {
      formData.append("parent_office", employeeParentOffice[0].ID);
    }

    const validateFdata: any = new FormData();
    validateFdata.append("first_name", formData.get("first_name"));
    validateFdata.append("last_name", formData.get("last_name"));

    if (data.mode === "edit") {
      submitEmployee(formData);
      return;
    }
    bdAxios
      .post("employees/validate", validateFdata, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(() => {
        formData.append("force_save", "1");
        submitEmployee(formData);
      })
      .catch((error) => {
        const ok: any = window.confirm(
          "Un employé avec ce nom existe déjà, êtes-vous certain qu'il ne s'agit pas d'un doublon?"
        );
        if (ok) {
          formData.append("force_save", "1");
          submitEmployee(formData);
        }
      });
  };

  const formatDateValue = (v: any) => {
    return v + "-01-01";
  };

  const handlePhotoInput = (e: any) => {
    // unsetting the error message
    setPhotoPreviewErrorMessage('')
    const img = new Image();
    const url = URL.createObjectURL(e.target.files[0])
    
    // File size check. must be less than 8MB
    if(e.target.files[0].size > 8388608){
      setSelectedPhoto(null)
      setShowPhotoPreview(false)
      setPhotoPreviewErrorMessage("Le poids de l'image doit etre inférieur à 8mb")
      return false
    }
    // Image pixel size check. must be square 1/1 
    img.onload = function () {
      if ((img.width / img.height) != 1){
        setPhotoPreviewErrorMessage(`L'image n'est pas de format carré. Height: ${img.height}px Width: ${img.width}px`)
        URL.revokeObjectURL(url);
        setSelectedPhoto(null)
        setShowPhotoPreview(false)
      }
    }
    img.src = url;

    setSelectedPhoto(e.target.files[0]);
    setFilePreview(URL.createObjectURL(e.target.files[0]));
    setShowPhotoPreview(true)
  };

  const removeSelectedPhoto = () => {
    setSelectedPhoto(null)
    setShowPhotoPreview(false)
    const photoField: any = document?.getElementById("photo");
    photoField?.setAttribute("value", null);
  };

  const manualSetEmployeeDesc = (value: any) => {
    if (value !== "undefined") {
      setEmployeeDesc(value);
    }
  };

  const handleFctChanges = (value: any, action: any) => {
    let notaryStatus = false;
    value.map((v: any) => {
      if (
        v.label.toLowerCase() === "notaire" ||
        v.label.toLowerCase() === "avocat"
      ) {
        notaryStatus = true;
      }
    });
    setIsNotary(notaryStatus);
  };
  return (
    <>
      <div className="inner-page">
        <div className="content">
          <div className="action-row">
            {/*<div onClick={() => navigate('/employes')} className="button">Retour</div>*/}
          </div>
          <h3>
            {employee?.fields?.first_name} {employee?.fields?.last_name}
          </h3>
          <form action="" id="employee-form" className="employee-form">
            <fieldset>
              <legend>Informations personnelles</legend>

              <div className="formRow">
                <label>Prénom *</label>
                <input
                  required
                  type="text"
                  name="first_name"
                  defaultValue={employee?.fields?.first_name}
                />
                <label>Nom *</label>
                <input
                  required
                  type="text"
                  name="last_name"
                  defaultValue={employee?.fields?.last_name}
                />
                {functionsOptions && functionsOptions.length > 0 ? (
                  <div className="formRow">
                    <label>Fonctions</label>
                    <Select
                      options={functionsOptions}
                      name="fcts[]"
                      onChange={handleFctChanges}
                      defaultValue={employeeFunctions}
                      className="small-select fct-select"
                      isMulti={true}
                    />
                  </div>
                ) : (
                  void 0
                )}
                {isNotary ? (
                  <div className="photo-section">
                    <label>Photo </label>
                      <p>Assurez-vous qu'elles soient dans un format carré</p>
                      {photoPreviewErrorMessage ? (
                        <p style={{color: 'red'}}>{photoPreviewErrorMessage}</p>
                      ) : (
                        void(0)
                      )}
                      <div style={{display: 'flex', flexDirection: 'row', columnGap: '0.6rem'}}>
                          <label
                            className="button"
                            htmlFor="photo"
                            style={{width: 'unset', margin: '0px'}}
                          >
                            {showPhotoPreview ? <FontAwesomeIcon icon={faPencil} /> : "Ajouter une photo"}
                          <input
                            onChange={handlePhotoInput}
                            id="photo"
                            type="file"
                            accept="image/*"
                            style={{ display: "none" }}
                            name="photo"
                          />
                        </label>
                        
                        <div className="button" style={{display: (showPhotoPreview ? 'flex' : 'none'), borderRadius:"0.375rem", margin: 0}}
                          onClick={() => {
                            removeSelectedPhoto()
													}} >
													<FontAwesomeIcon icon={faX} style={{display: 'flex', width: "unset" }}/> 
                          </div>
                        </div>                    
                    <div className="picture-wrapper">
                      {showPhotoPreview ? (
                        <>
                          <img className="picture" src={filePreview ? filePreview : employee.image} />
                        </>
                      ) : (
                        void(0)
                      )}
                      {selectedPhoto ? (
                        <>
                          {selectedPhoto.name}
                        </>
                      ) : (
                        void 0
                      )}
                    </div>
                  </div>
                ) : (
                  void 0
                )}

                {isNotary ? (
                  <>
                    <label>Biographie/Description</label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={getEmployeeDesc()}
                      onReady={(editor: any) => { }}
                      onChange={(event: any, editor: any) => {
                        const data = editor.getData();
                      }}
                      onBlur={(event: any, editor: any) => {
                        manualSetEmployeeDesc(editor.getData());
                      }}
                    />
                  </>
                ) : (
                  void 0
                )}
              </div>

              {isNotary ? (
                <div className="formRow">
                  <label>Genre des pronoms et titres </label>
                  <Select
                    options={genderOptions}
                    defaultValue={getEmployeeGender()}
                    name="titles_gender"
                    className="small-select"
                  />
                </div>
              ) : (
                void 0
              )}

              <div className="formRow">
                <label>Date de naissance *</label>
                <input
                  required
                  type={getDateFieldType()}
                  name="birthdate"
                  defaultValue={employee?.fields?.birthdate}
                />
              </div>

              <div className="formRow">
                <label>Adresse courriel *</label>
                <input
                  required
                  type="email"
                  name="email"
                  defaultValue={employee?.fields?.email}
                />
              </div>

              <div className="formRow">
                <label>Courriel Teams</label>
                <input
                  type="email"
                  name="teams_email"
                  defaultValue={employee?.fields?.teams_email}
                />
              </div>

              {isNotary ? (
                <div className="formRow">
                  <label>
                    <h5>Réseaux sociaux</h5>
                  </label>
                  {networkList
                    ? networkList.map((network: any, index: any) => {
                      return (
                        <div className="fieldsRow" key={index}>
                          <input
                            placeholder="Nom"
                            key={"name_" + index}
                            name="social_network_names[]"
                            type="text"
                            defaultValue={network.title}
                          />
                          <input
                            placeholder="URL"
                            key={"url_" + index}
                            name="social_network_urls[]"
                            type="text"
                            defaultValue={network.url}
                          />
                          <input
                            key={"img_" + index}
                            name="social_network_logos[]"
                            type="file"
                          />
                          {network.logo?.sizes?.icon ? (
                            <>
                              <img
                                src={network.logo?.sizes?.icon}
                                width={network.logo?.sizes["icon-width"]}
                                height={network.logo?.sizes["icon-height"]}
                              />
                            </>
                          ) : (
                            void 0
                          )}
                        </div>
                      );
                    })
                    : void 0}

                  <div onClick={addSocialNetwork} className="button">
                    Ajouter un réseau
                  </div>
                </div>
              ) : (
                void 0
              )}

              <br />
              <legend>
                <h5>Informations professionnelles</h5>
              </legend>
              <br />

              {employeeParentOffice &&
                employeeParentOffice.map((parentOffice: any) => {
                  return (
                    <div className="formRow" key={parentOffice.ID}>
                      <label>Étude</label>
                      <p>{parentOffice.post_title}</p>
                    </div>
                  );
                })}

              <div className="formRow">
                <label>Succursale</label>
                <Select
                  options={getOfficeOptions()}
                  name="office"
                  defaultValue={getEmployeeOffice()}
                  className="small-select officeSelect"
                  onChange={(e) => setEmployeeOffice(e.value)}
                />
              </div>

              {isNotary ? (
                <>
                  <div className="formRow">
                    <label>Associé?</label>
                    <input
                      type="checkbox"
                      defaultChecked={getIsAssociate()}
                      name="is_associate"
                    />
                  </div>
                  <div className="formRow">
                    <label>Année de début de pratique</label>
                    <input
                      type={getDateFieldType()}
                      defaultValue={formatDateValue(
                        employee?.fields?.practice_start_year
                      )}
                      name="practice_start_year"
                    />
                  </div>
                  <div className="formRow">
                    <label>Année de début de pratique d'avocat</label>
                    <input
                      type={getDateFieldType()}
                      defaultValue={formatDateValue(
                        employee?.fields?.lawyer_practice_start_year
                      )}
                      name="lawyer_practice_start_year"
                    />
                  </div>
                  <div className="formRow">
                    <label>Membre du Conseil d'administration?</label>
                    <input
                      type="checkbox"
                      defaultChecked={getIsCAMember()}
                      name="is_ca_member"
                    />
                  </div>
                </>
              ) : (
                void 0
              )}

              <div className="formRow">
                <label>Comités</label>
                <Select
                  isMulti={true}
                  name="committees[]"
                  options={committeeOptions}
                  defaultValue={getEmployeeCommittees()}
                />
                <small>Les notaires et les avocats peuvent participer à tous les comités, tandis que les autres employés peuvent rejoindre uniquement le Comité de collaboration et le Comité de bureau.</small>
              </div>
              <div className="action-row">
                <div onClick={submit} className="button">
                  Enregistrer
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </>
  );
};

export default EmployeeForm;
