import * as React from 'react';
import { useState, useEffect } from 'react';
import { bdAxios } from '../components/utils/Axios';
import { getToken, parseJwt } from '../components/utils/Common';
import Select from 'react-select'

function ValidateRatios(data: any) {
	const [offices, setOffices] = useState<any>();
	const [fexports, setFExports] = useState<Array<any>>([]);
	const [years, setYears] = useState<Array<any>>([]);

	const getOfficeOptions = () => {
		if (!offices) {
			return [];
		}
		const options: Array<any> = [];
		offices.map((office: any) => {
			options.push({value: office.ID, label: office.post_title})
		});

		return options;
	}

	useEffect(() => {

		const currentYear = new Date().getFullYear();
		let yrs = [];
		let year = currentYear - 10;
		let index = 0;
		while (year <= currentYear) {
			yrs.push({key:year, name: year});
			year++;
			index++;
		}
                yrs.reverse();
		setYears(yrs);

		let token = (getToken() ? getToken() : '');
		if (token === null) {
			return;
		}

		bdAxios.get('myoffices', {headers:{
			'Authorization': "Bearer " + token
		}}).then((r) => {
			const off: Array<any> = [];
			r.data.offices.map((office: any) => {
				if (office.post_parent === 0) {
					off.push(office);
				}
			})

			off.sort((a:any , b: any) => {
				return a.post_title.localeCompare(b.post_title);
			});
			setOffices(off);
		})
	}, []);

	 const submit = () => {
	 	let token = (getToken() ? getToken() : '');
	 	if (token === null) {
	 		return;
	 	}
	 	const form: any = document.querySelector("#export-form");
	 	const fd:any = new FormData(form);
	 	bdAxios.post('ratios/export', fd, {
	 		headers: {
				'Authorization': "Bearer " + token
			}
	 	}).then(r => {

	 		// let a = document.createElement('a');
	 		if (Array.isArray(r.data.data.excelData) && r.data.data.excelData.length == 0) {
	 			alert('Aucun ratio trouvé pour cette recherche');
	 			return;
	 		}
	 		setFExports(r.data.data.excelData);
	 		
	 		// a.setAttribute('href', r.data.data.excelData);
	 		// a.setAttribute('download', i.value+'.xls');
	 		// document.querySelector("body")?.append(a);
	 		// a.click();
	 		// a.remove();
	 	}).catch((error) => {
	 		alert('Aucun ratio trouvé pour cette recherche');
	 	});
	 }
	return(
		<>
		<form action="" id="export-form">
			<h2>Validation de ratio</h2>

		{ 
			fexports.length === 0 ?
			<fieldset>
				<div className="ratio-office-select-wrapper">
					<label>Étude</label>
					<Select
						options={getOfficeOptions()}
						name="office"
						className="office-select"
					/>
				</div>

				<label>Données pour la période financière se terminant en</label>
				<select name="year">
					<option value="" key='year_n'>Année</option>
					{ years ? 
						years.map((type:any) => {
						return (
							<option key={type.key} value={type.key}>{type.name}</option>
						)
					}) : void(0)}
				</select>
				<div className="btnrow">
					<input onClick={submit} type="button" defaultValue="Consulter" className="back" />
				</div>
			</fieldset>
			: 
			Object.keys(fexports).map((expKey:any) => {
				return(
					<>
						<a download={expKey+'.xls'} href={fexports[expKey]} target="_blank">Télécharger {expKey}</a>
					</>
				)

			})
		}
			
		</form>
			
		</>
	)
}

export default ValidateRatios;