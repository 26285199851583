import * as React from 'react';
import { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import { bdAxios } from '../components/utils/Axios';
import { parseJwt, getToken } from '../components/utils/Common';
import EmployeeForm from './forms/EmployeeForm';
import Select from 'react-select';
import { useNavigate, useLocation } from "react-router-dom";
import Loading from '../components/Loading';
import PopUpDeleteConfirmation from '../components/PopUpDeleteConfirmation'

function Employees() {

	const navigate = useNavigate();
	const location: any = useLocation();
	const [tab, setTab] = useState<string>('list');
	const [v, setV] = useState<number>(0);
	const [content, setContent] = useState<any>('');
	const [deletedEmployee, setDeletedEmployee] = useState<boolean>(false);
	const [selectedJob, setSelectedJob] = useState<any>('');
	const [searchValue, setSearchValue] = useState<string>('');
	const [selectedTitle, setSelectedTitle] = useState<any>('');
	const [pageTitle, setPageTitle] = useState<any>('Employés');
	const [offices, setOffices] = useState<any>();
	const [branches, setBranches] = useState<any>();
	const [selectedOffice, setSelectedOffice] = useState<any>();
	const [employees, setEmployees] = useState<any>();
	const [displayEmployees, setDisplayEmployees] = useState<any>();
	const [allEmployees, setAllEmployees] = useState<any>();
	const [selectedEmployee, setSelectedEmployee] = useState<any>();
	const [message, setMessage] = useState<string>('');
	const [tags, setTags] = useState<Array<any>>([]);
	const [popUpVisibility, setPopUpVisibility] = useState<boolean>(false);
	const [employeeToDelete, setEmployeeToDelete] = useState<number | null>(null);

	const showEmployee = (employeeId: number) => {
		let empObj: any = null;
		employees.map((employee: any) => {
			if (employee.ID === employeeId) {
				empObj = employee;
			}
		});
	}

	const back = () => {

	}
	const sortEmployees = () => {
		employees.sort((a : any, b : any) => a.post_title.localeCompare(b.post_title, 'fr'))
		return employees
	}

	const [searchFilter, setSearchFilter] = useState<string>('');
	const [jobFilter, setJobFilter] = useState<string>('');

	const isEmptyOrNull = (data: any) => {
		return (data === "" || data === null || data === void(0));
	}
	useEffect(() => {
		if (allEmployees) {
			// get ids of parent office and its children
			const childrenArr: Array<any> = [];
			offices.map((office: any) => {
				if (office.ID === selectedOffice) {
					if (office.post_parent === 0) {
						childrenArr.push(office.ID);
						const childOfficeArray = offices.filter((filteredOffice: any)=>filteredOffice.post_parent === office.ID);
						for (let officeObj in childOfficeArray){
							childrenArr.push(childOfficeArray[officeObj].ID);
						}
					}
				}
			})

			if (isEmptyOrNull(searchValue) && isEmptyOrNull(jobFilter) && isEmptyOrNull(selectedOffice) && isEmptyOrNull(selectedTitle)) {
				setEmployees(allEmployees)
				return;
			}
			const e: Array<any> = [...allEmployees];
			let validEmployees:any = [];
			if (!isEmptyOrNull(selectedOffice)) {
				e?.map((employee: any) => {
					if (null === employee.fields.branch) {
						return;
					}
					if(employee.fields.branch.ID == selectedOffice || parseInt(employee.fields.branch.post_parent) == selectedOffice) {
						validEmployees.push(employee);
					}
					if (childrenArr.length){
						for (let child in childrenArr) {
							if (employee.fields.branch.ID == childrenArr[child]) {
								if (!validEmployees.find((validEmployee: any) => validEmployee.ID == employee.ID)) {
									validEmployees.push(employee);
								}
							}
						}
					}
				});
			} else {
				validEmployees = [...allEmployees];
			}
			
			let filteredEmployees: Array<any> = [];

			if (!isEmptyOrNull(jobFilter)) {
				validEmployees.map((employee: any) => {
					employee.tags.map((tag: any) => {
						if (tag.term_id === jobFilter) {
							filteredEmployees.push(employee)
						}
					});
				})
			} else {
				filteredEmployees = [...validEmployees];
			}

			let employeesToDisplay: Array<any> = [];

			if (!isEmptyOrNull(selectedTitle)) {
				filteredEmployees.map((employee: any) => {
					if (!employee.fields.titles_list) {
						return;
					}
					employee.fields.titles_list.map((title: any) => {
						if (title.title === selectedTitle) {
							employeesToDisplay.push(employee)
						}
					});
				})
			} else {
				employeesToDisplay = [...filteredEmployees];
			}

			let realDisplay = [...employeesToDisplay];
			if (searchValue !== "") {
				realDisplay = realDisplay.filter((employee:any) => {
					let fullname = employee.fields?.first_name.toLowerCase() + ' ' + employee.fields?.last_name.toLowerCase();
					let lowersearch = searchValue.toLowerCase();
					return (fullname.includes(lowersearch) ||
						(employee.fields?.branch && employee.fields?.branch?.post_title.toLowerCase().indexOf(lowersearch) !== -1)
					)
				})
			}

			setEmployees(realDisplay)
		} else if (employees) {
			setEmployees(allEmployees);
		}
	}, [searchFilter, jobFilter, selectedOffice, selectedTitle, searchValue]);

	useEffect(() => {
		if (location?.state?.message) {
			setMessage(location.state.message);
			setTimeout(() => {
				setMessage('');
			}, 6000);
		}
		let token = (getToken() ? getToken() : '');
		if (token === null) {
			return;
		}

		bdAxios.get('myemployees', {headers:{
			'Authorization': "Bearer " + token
		}}).then((r) => {
			setAllEmployees(r.data.employees);
			setEmployees(r.data.employees);
		})
	}, [deletedEmployee]);

	useEffect(() => {
		let token = (getToken() ? getToken() : '');
		if (token === null) {
			return;
		}

		bdAxios.get('myoffices?withfunctions=1', {headers:{
			'Authorization': "Bearer " + token
		}}).then((r) => {
			setOffices(r.data.offices);
			setV(v => v+1);
		})

		bdAxios.get('myemployees', {headers:{
			'Authorization': "Bearer " + token
		}}).then((r) => {
			setAllEmployees(r.data.employees);
			setEmployees(r.data.employees);
			setTags(r.data.tags);
			setV(v => v+1);
		})
	}, [tab]);

	const getOfficeOptions = () => {
		if (!offices) {
			return [{label:"Sélectionner une étude", value:null}];
		}

		const options: Array<any> = [{label:"Sélectionner une étude", value:null}];
		const tree = new Map();

		offices.sort((a:any , b: any) => {
			return a.post_title.localeCompare(b.post_title);
		});
		
		offices.map((office: any) => {
			if (office.post_parent == '0' || !office.post_parent) {
				tree.set(office.ID, {parent: office, children:[office]});
			}
		});

		// only show parent office on dropdown
		/*offices.map((office: any) => {
			if (office.post_parent != '0' || !!office.post_parent) {
				const v = tree.get(office.post_parent);
				v.children.push(office);
				tree.set(office.post_parent, v);
			}
		})*/
		tree.forEach((group: any) => {

			let g:any = {
				// label: group.parent.post_title, // label
				options: []
			};
			group.children.map((child:any) => {
				g.options.push({label: "  "+child.post_title, value: child.ID});
			});

			options.push(g);
		})

		return options;
	}

	const deleteEmployee = (id: any) => {
		let token = (getToken() ? getToken() : '');
		if (token === null) {
			return;
		}
		const user = parseJwt(token);
		if (user.data.roles && Array.isArray(user.data.roles)) {

		} else {

		}
		bdAxios.post('delete/employee',{employee_id: id}, 
		{headers: {
			'Authorization': "Bearer " + token
			}
		}).then((r) => {
			setV(v => v+1);
			setTab('list');
			setDeletedEmployee(!deletedEmployee);
			navigate('/employes', {state: {message:'Employé.e supprimé.e avec succès.'}})
			setTimeout(() => {
				setMessage('');
			}, 3000)
		})
	}

	const getJobsOptions = () => {
		const options: any = [{label: "Sélectionner une fonction", value: null}];
		if (tags && tags.length > 0) {
			tags.map((tag: any) => {
				options.push({label: tag.name, value: tag.term_id});
			});
		}

		return options;
	}

	const selectJob = (evt: any) => {
		setJobFilter(evt.value)
	}

	const selectOffice = (evt: any) => {
		setSelectedOffice(evt.value);
		
		// setEmployees(dEmployees);
		// setV(v => v+1);
	}

	const selectTitle = (evt: any) => {
		setSelectedTitle(evt.value);
		
		// setEmployees(dEmployees);
		// setV(v => v+1);
	}

	useEffect(() => {
		setV(v => v+1);
	}, [message]);

	const selectEmployee = (employee: any) => {
		setMessage('');
		setTab('employee')
	}

	const getEmployeeTags = (employee:any) => {
		if (employee && employee.tags) {
			let string: string = '';
			employee.tags.map((tag:any) => {
				string += tag.name +"<br>";
			});
			return string;
		}

		return '';
	}
	const getEmployeeTitles = (employee:any) => {
		if (employee && employee.fields.titles_list && employee.fields.titles_list !== null) {
			let string: string = '';
			employee.fields.titles_list.map((title:any) => {
				string += title.title +"<br>";
			});
			return string;
		}

		return '';
	}

	const getTitlesOptions = () => {
		const options:any  =[{label:"Sélectionner", value:null}];

		const titleList: any = [];
		if (allEmployees && allEmployees.length > 0) {
			allEmployees.map((employee:any) => {
				if (employee.fields.titles_list) {
					employee.fields?.titles_list?.map((title: any) => {
						if (titleList.indexOf(title.title) === -1) {
							titleList.push(title.title);
						}
					})
				}
			});
		}

		titleList.map((title:any) => {
			options.push({label: title, value: title});
		})
		return options;
	}
	return (
		<Layout title="Accueil" classes="accueil-page">

			<PopUpDeleteConfirmation popUpVisibility={popUpVisibility} setPopUpVisibility={setPopUpVisibility} deleteFunction={ () => deleteEmployee(employeeToDelete) }/>

			<div className="inner-page">
				<h1 className="title">{pageTitle}</h1>
				{ message !== '' ?
					<div className="notice p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800" role="alert">
					  <span className="font-medium">{message}</span>
					</div>
					: void(0)
				}
				
				<div className="content">
				{ 
					(tab === "list" && employees) ? 
						<>	
							<form>
								<fieldset>
									<input type='text' placeholder="Rechercher" name='search' onChange={e => setSearchValue(e.target.value)} />
									<Select
										options={getOfficeOptions()}
										defaultValue={{label:"Sélectionner une étude", value:null}}
										onChange={selectOffice}
									/>

									<div className="filters">
										<Select
											options={getJobsOptions()}
											defaultValue={{label:"Sélectionner une fonction", value:null}}
											onChange={selectJob}
											className="small-select"
										/>
										<input 
											onClick={e => navigate('/employes/ajouter')}
											style={{ display: 'inline-block' }}
											type="button" className="button"
											defaultValue="+ Ajouter un employé"/>
									</div>
								</fieldset>
							</form>

						{ 
							
							<div className="mt-5">
								<table className="employees-table">
								<thead>
									<tr>
										<td>Nom</td>
										<td>Étude</td>
										<td>Fonction</td>
										<td className="small-text">Voir</td>
										<td className="small-text">Supprimer</td>
									</tr>
								</thead>
								<tbody>
								{
									(employees && employees.length > 0) ? 
									sortEmployees().map((employee: any) => {
										if (employee.fields.branch && employee.fields.branch.post_title[0].match(/^[0-9]/) && offices){
											let child = offices.find((office:any) => office.ID === parseInt(employee.fields.branch.ID));
											if (child) {
												employee.fields.branch = offices.find((office:any) => office.ID === parseInt(child.post_parent));
											}

										}
										return (
											<tr key={employee.ID}>
												<td>{employee.fields.first_name} {employee.fields.last_name}</td>
												{ employee.fields.branch ?
													<td>{employee.fields.branch.post_title}</td>
													: <td></td>
												}
												<td dangerouslySetInnerHTML={{ __html: getEmployeeTags(employee) }}></td>
												<td>
													<div className="button" onClick={(e) => { employee.lastMod = Date.now(); navigate('/employes/'+employee.ID) }}>Voir</div>
													</td>													<td><div className="button delete"
														onClick={ () => { setPopUpVisibility(true); setEmployeeToDelete(employee.ID) }} >Supprimer</div></td>
											</tr>
										);
									})
									: void(0)
								}
								</tbody>	
								</table>
							</div>
						}
						
						</>
					: <Loading />
				}
				{ 
					tab === "employee" ?
						<>
							<EmployeeForm employee={selectedEmployee} setTab={setTab} setSelectedEmployee={setSelectedEmployee} setMessage={setMessage}/>
						</>
					: void(0)
				}
				</div>
			</div>
		</Layout>
	);
}

export default Employees;