import * as React from 'react';
import { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import ShowOffice from './ShowOffice';
import { bdAxios } from '../components/utils/Axios';
import { parseJwt, getToken } from '../components/utils/Common';
import EditOffice from './forms/EditOffice';
import { useNavigate, useLocation } from 'react-router-dom';
import Loading from '../components/Loading';
import PopUpDeleteConfirmation from '../components/PopUpDeleteConfirmation'

function Offices() {
	const navigate = useNavigate();
	const location: any = useLocation();

	const [tab, setTab] = useState<string>('list');
	const [content, setContent] = useState<any>('');
	const [offices, setOffices] = useState<any>([]);
	const [displayOffices, setDisplayOffices] = useState<any>();
	const [branches, setBranches] = useState<any>();
	const [selectedOffice, setSelectedOffice] = useState<any>();
	const [selectedOfficeObjet, setSelectedOfficeObjet] = useState<any>();
	const [v, setV] = useState<number>(0);
	const [message, setMessage] = useState<string>('');
	const [popUpVisibility, setPopUpVisibility] = useState<boolean>(false);
	const [officeToDelete, setOfficeToDelete] = useState<number | null>(null);

	useEffect(() => {
		if (tab === "show") {
			setContent(<ShowOffice/>);
		} else {
			setContent('');
		}
	}, [tab]);

	useEffect(() => {
		if (location?.state?.message) {
			setMessage(location.state.message);
			setTimeout(() => {
				setMessage('');
			}, 6000);
		}
		let token = (getToken() ? getToken() : '');
		if (token === null) {
			return;
		}

		setSelectedOffice(null);

		bdAxios.get('myoffices', {headers:{
			'Authorization': "Bearer " + token
		}}).then((r) => {
			const branches: any = [];

			setOffices(r.data.offices);

			const tree = new Map();
			const off: any = [...r.data.offices];
			off.sort((a:any , b: any) => {
				return a.post_title.localeCompare(b.post_title);
			});

			off.map((office: any) => {
				if (office.post_parent == '0' || !office.post_parent) {
					tree.set(office.ID, {parent: office, children:[]});
				}
			});

			off.map((office: any) => {
				if (office.post_parent != '0' || !!office.post_parent) {
					const v = tree.get(office.post_parent);
					v.children.push(office);
					tree.set(office.post_parent, v);
				}
			})

			const displayOffices:any = [];
			tree.forEach((group: any) => {
				displayOffices.push(group.parent);
				group.children.map((child:any) => {
					child.isChild = true;
					displayOffices.push(child);
				})
			})
			
			setOffices(displayOffices);
			setDisplayOffices(displayOffices);
			setV(v => v+1);
		})
	}, []);

	const reset = () => {
		setTab('list');
	}

	const deleteOffice = (id: any) => {
		let token = (getToken() ? getToken() : '');
		if (token === null) {
			return;
		}
		const user = parseJwt(token);
		if (user.data.roles && Array.isArray(user.data.roles)) {

		} else {

		}
		bdAxios.post('delete/office',{office_id: id}, 
		{headers: {
			'Authorization': "Bearer " + token
			}
		}).then((r) => {
			reset();
			setMessage('Étude supprimée avec succès.')
			setTimeout(() => {
				setMessage('');
			}, 5000);
		})
	}

	const search = (terms: string) => {

		if (terms === "") {
			setDisplayOffices(offices);
		}

		const filtered: Array<any> = [];
		offices.map((office: any) => {
			if (office.post_title.toLowerCase().indexOf(terms.toLowerCase()) > -1) {
				filtered.push(office);
			}
		});

		setDisplayOffices(filtered);
	}
	return (
		<Layout title="Accueil" classes="accueil-page">

			<PopUpDeleteConfirmation popUpVisibility={popUpVisibility} setPopUpVisibility={setPopUpVisibility} deleteFunction={ () => deleteOffice(officeToDelete) }/>

			<div className='inner-page'>
				<h1 className="title">Études</h1>
				
				<div className="content">
					{ message !== '' ?
						<div className="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800" role="alert">
						  <span className="font-medium">{message}</span>
						</div>
						: void(0)
					}
					{ tab === 'list' && (displayOffices && displayOffices.length > 0) ?
						<>
							<form>
								<fieldset>
									<input type='text' placeholder="Rechercher" name='search' onChange={e => search(e.target.value)} />
									<input onClick={e => navigate('/etudes/ajouter') } type="button" className="button" defaultValue="Ajouter un bureau ou succursale" />
								</fieldset>
							</form>
							<table className="employees-table">

								<thead>
									<tr>
										<td>Nom</td>
										<td>Afficher/Modifier</td>
										<td>Supprimer</td>
									</tr>
								</thead>
								<tbody>
								{
									(displayOffices && displayOffices.length > 0) ?
									displayOffices.map((office: any) => {
										return (
											<tr key={office.ID} className={office.isChild ? "children-row" : ""}>
												<td>{office.post_title}</td>
												<td><div className="button" onClick={(e) => { office.lastMod = Date.now(); navigate("/etudes/"+office.ID, {state: {office:office}})}}>Voir</div></td>
												<td><div onClick={ () => { setPopUpVisibility(true); setOfficeToDelete(office.ID) }} className="button delete">Supprimer</div></td>
											</tr>
										);
									})
									: void(0)
								}
								</tbody>
							</table>
							</>
						: <Loading />
					}
				</div>
			</div>
		</Layout>
	);
}

export default Offices;