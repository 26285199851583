import * as React from 'react';
import { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import EmployeeForm from './forms/EmployeeForm';

function AddEmployee() {

	return (
		<Layout title="Employé" classes="accueil-page">
			<div className="inner-page">
				<EmployeeForm mode="add"/>
			</div>
		</Layout>

	)
}
export default AddEmployee;