import * as React from 'react';
import { useState, useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { bdAxios } from '../../components/utils/Axios';
import { getToken } from '../../components/utils/Common';
import Select from 'react-select'
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faX } from '@fortawesome/free-solid-svg-icons';

interface EditOfficeProps {
    office: any,
}

const EditOffice = (office: any) => {
	const navigate = useNavigate();

	const [data, setData] = useState<any>();
	const [v, setV] = useState(0);
	const [officeContent, setOfficeContent] = useState<any>(office?.office?.post_content);
	const [employees, setEmployees] = useState<any>();
	const [fullMemberList, setFullMemberList] = useState<any>();
	const [networkList, setNetworkList] = useState<any>();
	const [telephonyList, setTelephonyList] = useState<any>();
	const [scheduleList, setScheduleList] = useState<any>();
	const [isBranch, setIsBranch] = useState<any>();
	const [isHQ, setIsHQ] = useState<any>();
	const [showLegal, setShowLegal] = useState<any>();
	const [selectedOffice, setSelectedOffice] = useState<any>(office.office);
	const [headerPhoto, setHeaderPhoto] = useState<any>();
	const [headerPhotoPreviewURL, setHeaderPhotoPreviewURL] = useState<any>(selectedOffice?.photo);
	const [photoPreviewErrorMessage, setPhotoPreviewErrorMessage] = useState<String>('');
	const [offices, setOffices] = useState<any>();
	const [officeOptions, setOfficeOptions] = useState<any>();
	const [branchOption, setBranchOption] = useState<any>();
	const [privicyPolicyDocument, setPrivicyPolicyDocument] = useState<any>(selectedOffice?.fields?.legal_document);
	const [showPrivicyPolicySaveText, setShowPrivicyPolicySaveText] = useState<any>(false);
	

	useEffect(() => {
		setIsBranch(office?.office?.fields?.is_branch)
		setNetworkList(office?.office?.fields?.social_network_list)
		setTelephonyList(office?.office?.fields?.telephony?.numbers)
		setScheduleList(office?.office?.fields?.branch_hours);
		setSelectedOffice(office?.office)

		let token = (getToken() ? getToken() : '');
		if (token === null) {
			return;
		}
		bdAxios.get('myemployees', {headers:{
			'Authorization': "Bearer " + token
		}}).then((r) => {
			setEmployees(r.data.employees);
		});
		bdAxios.get('myoffices?light=true', {headers:{
			'Authorization': "Bearer " + token
		}}).then((r) => {
			setOffices(r.data.offices);

			const options: Array<any> = [];
			r.data.offices.sort((a:any , b: any) => {
				return a.post_title.localeCompare(b.post_title);
			});
			r.data.offices.map((office:any) => {
				options.push({value: office.ID, label: office.post_title})
			});

			setOfficeOptions(options);

			const option:Array<any> = [];

			if (office.office) {
				let targetId = office.office.ID;
				if (office.office.post_parent != 0) {
					targetId = office.office.post_parent;
				}

				r.data.offices.map((off:any) => {
					if (off.ID == targetId) {
						option.push({label: off.post_title, value: off.ID})
					}
				})

				setBranchOption(option);
			}
			
		});
	}, []);

	const setValue = (name: any, value: any) => {
		const d = data;
		d[name] = value;
		setData(d);
	}

	const getValue = (name: string, object: any) => {
		if (object && object.hasOwnProperty(name)) {
			return object[name];
		}

		return '';
	}

	const hasFields = () => {
		return (selectedOffice && selectedOffice.hasOwnProperty('fields'));
	}

	const getIsChecked = (prop:any) => {
		if (hasFields() && selectedOffice.fields.hasOwnProperty(prop)) {
			return selectedOffice.fields[prop] === true ? true : false 
		}
		return false;
	}

	const getAppointmentEmail = () => {
		if (hasFields()
			&& selectedOffice.fields.hasOwnProperty('email_contacts')
			&& selectedOffice.fields.email_contacts.hasOwnProperty('generic_contact_email')
		) {
			return selectedOffice.fields.email_contacts.appointment_email.generic_contact_email
		}

		return '';
		
	}
	const getGenericContactEmail = () => {
		return selectedOffice?.fields?.email_contacts?.generic_contact_email;
	}
	const getPaymentMethods = () => {
		return selectedOffice?.fields?.payment_modes;
	}

	const getAddress = (key:string) => {
		const add:any = selectedOffice?.fields?.address_group;
		if (add && add.hasOwnProperty(key)) {
			return add[key];
		}

		return '';
	}

	const submit = () => {
		let token = (getToken() ? getToken() : '');
		if (token === null) {
			return;
		}
		const isActiveChecked: any = document.querySelector('[name=is_active]:checked');
		if (!isActiveChecked) {
			alert('Vous devez choisir actif ou inactif');
			return;
		}
		const form: any = document.querySelector("#office-edit-form")
		const formData = new FormData(form);
		formData.append('post_content', officeContent);

		if (selectedOffice) {
			// update
			formData.append('id', selectedOffice.ID);
			bdAxios.post('update/office', formData, {
				headers: {
					'authorization': 'Bearer ' + token,
					'Content-Type': 'multipart/form-data'
				}
			}).then((r) => {
				navigate('/etudes', {state:{message:"Étude mise à jour avec succès."}})
			})
		} else {
			bdAxios.post('create/office', formData, {
				headers: {
					'authorization': 'Bearer ' +  token,
					'Content-Type': 'multipart/form-data'
				}
			}).then((r) => {
				navigate('/etudes', {state:{message:"Étude créée avec succès."}})
			})
		}
	}


	const hasAddress = () => {
		return selectedOffice?.fields?.address_group || isBranch === true;
	}

	const addSocialNetwork = () => {
		let sNetworks: any = networkList;
		if (!sNetworks) {
			sNetworks = [];
		}

		sNetworks.push({label:'',url:''});
		setNetworkList(sNetworks);
		setV(v => v + 1)
	}

	const addTelephony = () => {
		let tList: any = telephonyList;
		if (!tList) {
			tList = [];
		}

		tList.push({label:'',url:''});
		setTelephonyList(tList);
		setV(v => v + 1)
	}

	const addSchedule = () => {
		let sList: any = scheduleList;
		if (!sList) {
			sList = [];
		}

		sList.push({days:'',hours:''});
		setScheduleList(sList);
		setV(v => v + 1)
	}

	useEffect(() => {
		const memberList: any = [];
		if (!employees) {
			return;
		}
		employees.map((employee: any) => {
			memberList.push({label: employee?.fields?.first_name + ' ' + employee?.fields?.last_name, value: employee.ID})
		})

		setFullMemberList(memberList);
	}, [employees]);


	const getMyMembers = () => {
		if (!selectedOffice?.fields?.members) {
			return [];
		}

		const options: Array<object> = [];
		selectedOffice.fields.members.map((employee:any) => {
			options.push({label: employee?.post_title, value: employee.ID})
		})

		return options;
	}

	const getShowLegal = () => {
		return office?.office?.fields?.show_legal;
	}

	const getLegalText = () => {
		return office?.office?.fields?.legal_contact;
	}

	const getPrivicyPolicyFileName = () => {

		if(privicyPolicyDocument){
			// ID is the wordpress file property
			if (privicyPolicyDocument.ID){

				// the wordpress file uses 3 properties to create a descriptive file name
				const title = privicyPolicyDocument.title? privicyPolicyDocument.title : ''
				const type = privicyPolicyDocument.subtype? privicyPolicyDocument.subtype : ''

				return `${title}.${type}`

			} else {

				// the new file will only use the name
				const name = privicyPolicyDocument.name
				return `${name}`

			}
		}

		return ''
	}

	const getBranchOffice = () => {
		if (!offices) {
			return [];
		}
		const option:Array<any> = [];

		let targetId = office.office.ID;
		if (office.office.post_parent !== 0) {
			targetId = office.office.post_parent;
		}

		offices.map((off:any) => {
			if (off.ID === targetId) {
				option.push({label: off.post_title, value: off.ID})
			}
		})

		return option;
	}

	const handlePhotoInput = (e: any) => {
		// unsetting the error message
		setPhotoPreviewErrorMessage('')
		const img = new Image();
		const url = URL.createObjectURL(e.target.files[0])
		
		// Image pixel size check. must be square 1/1 
		img.onload = function () {
		  if ((img.width / (img.height * 2)) != 1){
			setPhotoPreviewErrorMessage(`L'image n'est pas ratio de 2 / 1. Width: ${img.width}px Height: ${img.height}px`)
			URL.revokeObjectURL(url);
			setHeaderPhoto(null)
			setHeaderPhotoPreviewURL(false)
		  }
		}
		img.src = url;
		
		setHeaderPhoto(e.target.files[0]);
		setHeaderPhotoPreviewURL(url)
	  };

	  const removeSelectedPhoto = () => {
		setHeaderPhoto(null)
		setHeaderPhotoPreviewURL(false)
		const photoField: any = document?.getElementById("headerphoto");
		photoField?.setAttribute("value", null);
	  };

	return (
		<>
			<form id="office-edit-form">
				<fieldset>
					<legend><h2>Informations générales</h2></legend>
					{ isBranch == true ? <label>Numéro civique et Rue</label> : <label>Nom</label>}
					<input name="title" type="text" defaultValue={getValue('post_title', selectedOffice)} />

					{ !isBranch ?
						<>
	                		<label>Description</label>
							<CKEditor
	                    		editor={ ClassicEditor }
	                    		data={getValue('post_content', selectedOffice)}
	                    		onReady={ (editor: any) => {
								} }
	                    		onChange={ ( event: any, editor: any ) => {
									const data = editor.getData();
	                    		} }
	                    		onBlur={ ( event: any, editor: any ) => {
									setOfficeContent(editor.getData());
	                    		} }
	                    		onFocus={ ( event: any, editor: any ) => {
								} }
							/>
							
	                		<label htmlFor="headerphoto">Photo d'étude</label>
	                		<p className="note" style={{ color: 'red' }}> Assurez vous qu'elle soit dans un ratio de 2:1</p>
							<div style={{display: 'flex', flexDirection: 'row', columnGap: '0.6rem'}}>
                        	  <label
                        	    className="button"
                        	    htmlFor="headerphoto"
                        	    style={{width: 'unset', margin: '0px'}}
                        	  >
                        	    {headerPhoto ? <FontAwesomeIcon icon={faPencil} /> : "Ajouter une photo"}
                        	  <input
                        	    onChange={handlePhotoInput}
                        	    id="headerphoto"
                        	    type="file"
                        	    accept="image/*"
                        	    style={{ display: "none" }}
                        	    name="headerphoto"
                        	  />
                        	</label>
                        	<div className="button" style={{display: (headerPhoto ? 'flex' : 'none'), borderRadius:"0.375rem", margin: 0}}
                        	  onClick={() => {
                        	    removeSelectedPhoto()
							}} >
							<FontAwesomeIcon icon={faX} style={{display: 'flex', width: "unset" }}/> 
                        	  </div>
                        	</div>
							
							{photoPreviewErrorMessage ? (
                        		<p style={{color: 'red'}}>{photoPreviewErrorMessage}</p>
                      		) : (
                      		  void(0)
                      		)}
							{headerPhotoPreviewURL ? (
								<img id="header-photo-preview" src={headerPhotoPreviewURL} />
							)
							: void(0)}
	                	</>
	                	: void(0)
	                }
	                

		            <label>Statut</label>
	                <div className="formRow">
		                <label>Actif<input type="radio" name="is_active" value="1" defaultChecked={selectedOffice?.fields?.is_active} /></label>
		                <label>Inactif<input type="radio" name="is_active" value="0" defaultChecked={!selectedOffice?.fields?.is_active} /></label>
	                </div>
									{office.office?.post_parent !== 0 &&
										<div className="formRow">
											<label>Siège social<input onChange={e => setIsHQ(e.target.checked)} type="checkbox" name="is_hq" value="1" defaultChecked={getIsChecked('is_hq')} /></label>
											<label>Succursale<input onChange={e => setIsBranch(e.target.checked)} type="checkbox" name="is_branch" value="1" defaultChecked={getIsChecked('is_branch')} /></label>
										</div>
									}
					</fieldset>
					{ isBranch == true ?
					<fieldset>
						<legend>Équipe</legend>

						<Select
							isMulti={true}
							options={fullMemberList}
							defaultValue={getMyMembers()}
							name="members[]"
						/>
					</fieldset>
	                	: void(0)
	                }
	                { isBranch == true ?
	                	<fieldset>
	                		<legend><h2>Information de succursale</h2></legend>

	                		<label>Étude</label>
 							{ 
 								branchOption || officeOptions ?
	 								<Select
	 									options={officeOptions}
	 									defaultValue={branchOption}
	 									name="office"
	 								/>
 								: void(0)
 							}
                			

	                		<br/>
		                	<label>Accessible pour personnes à mobilité réduite?<input type="checkbox" name="is_accessible" value="1" defaultChecked={getIsChecked('is_accessible')} /></label>
		                	<label>Service à domicile?<input type="checkbox" name="at_home" value="1" defaultChecked={getIsChecked('at_home')} /></label>
	                		<legend>Adresse</legend>
	                		<label>Numéro civique & rue</label>
	                		<input type="text" placeholder="Adresse" defaultValue={getAddress('address')} name="address"/>
	                		<input type="text" placeholder="Ville" defaultValue={getAddress('city')} name="city"/>
	                		<input type="text" placeholder="Code Postal" defaultValue={getAddress('postal_code')} name="postal_code"/>
	                		<input type="text" placeholder="Province" defaultValue={getAddress('province')} name="province"/>
	                		<label>Coordonnées</label>
	                		<input type="text" placeholder="Latitude" defaultValue={selectedOffice?.fields?.coordinates?.latitude} name="latitude"/>
	                		<input type="text" placeholder="Latitude" defaultValue={selectedOffice?.fields?.coordinates?.longitude} name="longitude"/>

	                		<div className="field-group">
	                			<legend><h5>Heures d'ouvertures</h5></legend>
	                			{
	                				scheduleList ? scheduleList.map((schedule: any, index: any) => {
	                				return (
	                						<div className="fieldsRow" key={index}>
	                							<input placeholder="Jours (ex: Lundi ou Lundi, mardi)" key={'name_'+index} name="branch_hours_days[]" type="text" defaultValue={schedule.days} />
	                							<input placeholder="Horaire (ex: 9 à 5)" key={'number_'+index} name="branch_hours_hours[]" type="text" defaultValue={schedule.hours}  />
	                						</div>
	                					)
	                				}) :void(0)
	                			}
	                			<div onClick={addSchedule} className="button">Ajouter une plage horaire</div>
	                		</div>
	                	</fieldset>
	                	: void(0)
	                }

	                <fieldset>
	                <legend><h2>Demande d'informations</h2></legend>
					
				{/*	<label>
						Demandes de rendez-vous
						<input name="appointment_email" type="text" defaultValue={getAppointmentEmail()} />
					</label>*/}
					<label>
						Responsable de la réception des demandes d'informations
						<input name="generic_contact_email" type="text" defaultValue={getGenericContactEmail()} />
					</label>

					<div className="field-group">
						<legend><h5>Numéros de téléphones, fax, etc.</h5></legend>
						{
							telephonyList ? telephonyList.map((telephony: any, index: any) => {
							return (
									<div className="fieldsRow" key={index}>
										<input placeholder="Nom" key={'name_'+index} name="telephony_names[]" type="text" defaultValue={telephony.label} />
										<input placeholder="Numéro" key={'number_'+index} name="telephony_numbers[]" type="text" defaultValue={telephony.number}  />
									</div>
								)
							}) :void(0)
						}
						<div onClick={addTelephony} className="button">Ajouter un numéro</div>
					</div>

					<div className="field-group">
						<legend><h5>Réseaux sociaux</h5></legend>
						{
							networkList ? networkList.map((network: any, index: any) => {
							return (
									<div className="fieldsRow" key={index}>
										<input placeholder="Nom" key={'name_'+index} name="network_names[]" type="text" defaultValue={network.label} />
										<input placeholder="URL" key={'url_'+index} name="network_urls[]" type="text" defaultValue={network.url}  />
									</div>
								)
							}) :void(0)
						}

						<div onClick={addSocialNetwork} className="button">Ajouter un réseau</div>
					</div>
					</fieldset>
					<fieldset>
					<legend><h2>Autres Informations</h2></legend>
					<div className="formRow">
						<label>
							Méthodes de paiement
							<input name="payment_modes" type="text" defaultValue={getPaymentMethods()} onChange={e=>setValue('payment_modes', e.target.value)} />
						</label>
					</div>
					
					{
						!isBranch ?
							<div className="formRow">
								<label>Responsable et procédures de la confidentialité des renseignements personnels</label>
								<input defaultChecked={getShowLegal()} onChange={e => setShowLegal(e.target.checked)} value="1" name="show_legal" type="checkbox" /><br/>
								{ showLegal || getShowLegal()  ? 
									<>

										<h4>
											Politique de confidentialité 
										</h4>
										<br/>
										<input defaultValue={getLegalText()} type="text" placeholder="Texte" style={{ width: '90%'}} name="legal_contact" /><br/>

										<div style={{display:'flex', columnGap: '2%'}}>
												<div style={{display:'flex', rowGap: '6px', flexDirection:'column'}}>
												<label className="button" htmlFor="legal_document" style={{display: 'flex', width: "unset", margin: 0}}>
													{
														privicyPolicyDocument ? 
														<FontAwesomeIcon icon={faPencil} />
														: 'Téléverser un document '
													}
													<input type="file" accept="application/pdf" name="legal_document" id="legal_document" style={{display:'none'}} 
														onChange={
															e=>{
																setPrivicyPolicyDocument(e.target.files?.length ? e.target.files[0] : null)
																setShowPrivicyPolicySaveText(true)
															}
														}
													/>
												</label>
													{
														privicyPolicyDocument ? 
														<FontAwesomeIcon className="button" icon={faX} style={{display: 'flex', width: "unset", margin: 0, borderRadius:"0.375rem"}}
														onClick={() => {
															setPrivicyPolicyDocument(null) 
															setShowPrivicyPolicySaveText(false)
														}} /> 
														: void(0)
													}

													</div>
												<div style={{display: 'flex', alignItems: 'center'}}>
													<div>
													<a href={privicyPolicyDocument?.url} style={{textDecoration:'underline'}} target="_blank">{getPrivicyPolicyFileName()}</a>
														{
															showPrivicyPolicySaveText ? 
															<p style={{color:'red'}} >N'oubliez pas de sauvegarder le formulaire pour terminer le transfert du nouveau document. </p>
															: void(0)
														}
													</div>
												</div>
										</div>
									</>
									: void(0)
								}
								
							</div>
						: void(0)
					}
					
					<div className="formRow">
						<label>
							Nombre d'associés
							<input name="associates_number" type="text" defaultValue={office.office?.fields?.associates_number} onChange={e=>setValue('associates_number', e.target.value)} />
						</label>
					</div>
					<div className="formRow">
						<label>
							Nombre de notaires salariés
							<input name="salaried_number" type="text" defaultValue={office.office?.fields?.salaried_number} onChange={e=>setValue('salaried_number', e.target.value)} />
						</label>
					</div>
					<div className="formRow">
						<label>
							Nombre d'employés
							<input name="employees_number" type="text" defaultValue={office.office?.fields?.employees_number} onChange={e=>setValue('employees_number', e.target.value)} />
						</label>
					</div>

					<div className="btnrow">
						<div onClick={submit} className="button">Enregistrer</div>
					</div>
				</fieldset>
			</form>
			
		</>
	)
}

export default EditOffice;