import * as React from 'react';
import { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import EditOffice from './forms/EditOffice';

function AddOffice() {

	return (
		<Layout title="Employé" classes="accueil-page">
			<div className="inner-page">
				<div className="content">
					<EditOffice mode="add"/>
				</div>
			</div>
		</Layout>

	)
}
export default AddOffice;