import * as React from 'react';

type PopUpDeleteConfirmationProps = {
    popUpVisibility : boolean,
    setPopUpVisibility : Function,
    deleteFunction : Function,
}

function PopUpDeleteConfirmation({popUpVisibility, setPopUpVisibility, deleteFunction}: PopUpDeleteConfirmationProps ) {

    return (
    <div id="pop-up-background" className={popUpVisibility ? 'active' : ''}>
        <div id='pop-up'>
            <p>Êtes-vous sûr de bien vouloir supprimer cet élément?</p>
            <div className='pop-up-button-container'>
                <button id='cancel-delete' onClick={ ()=> { setPopUpVisibility(false); }} className="button">Annuler</button>
                <button id='confirm-delete' onClick={ () => { setPopUpVisibility(false); deleteFunction() }} className="button delete">Supprimer</button>
            </div>
        </div>
    </div>
    )
}

export default PopUpDeleteConfirmation;