import * as React from 'react';
import ConnexionImageBack from '../resources/images/connexion_image_back.svg';
import ConnexionImageMiddle from '../resources/images/connexion_image_middle.svg';
import ConnexionImageFront from '../resources/images/connexion_image_front.svg';


function Loading() {
  return (
    <div className="login-page loading" >
        <div className='login-page__background'>
        <div className='login-page__background__column2'>
            <div className='login-page__background__column2__container'>
                <img src={ConnexionImageBack} alt='Network' />
                <img src={ConnexionImageMiddle} alt='Network' />
                <img src={ConnexionImageFront} alt='Network' />
                <div className='loading-title'><h1>Chargement...</h1></div>
            </div>
        </div> 
        </div>
    </div>
  );
}

export default Loading;
