import * as React from 'react';
import { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import EmployeeForm from './forms/EmployeeForm';
import Loading from '../components/Loading';
import { bdAxios } from '../components/utils/Axios';
import { getToken } from '../components/utils/Common';
import { useParams, useLocation} from 'react-router-dom';

function Employee() {
	const params = useParams();
	const location: any = useLocation();
	const [selectedEmployee, setSelectedEmployee] = useState<any>();

	useEffect(() => {
		getSelectedEmployee();
	}, [])

	const getSelectedEmployee = () => {
		if (location && location.state && location.state.employee) {
			if ((Date.now() - location.state?.employee?.lastMod) < 1000) {
				setSelectedEmployee(location.state.employee);
			}
		}

		let token = (getToken() ? getToken() : '');
		if (token === null) {
			return;
		}
		bdAxios.get('employee/'+params.id, {headers:{
			'Authorization': "Bearer " + token
		}}).then((r) => {
			setSelectedEmployee(r.data.employee);
		});
	}
	
	return (
		<Layout title="Employé" classes="accueil-page">
			<div className="inner-page">
			{ 
				selectedEmployee ?
					<EmployeeForm mode="edit" employee={selectedEmployee} />
				: <Loading />
			}
			</div>
		</Layout>

	)
}
export default Employee;