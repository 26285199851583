import * as React from 'react';
import { Link } from 'react-router-dom';
import {routes} from './utils/Routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from "react-router-dom";
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';


function MenuMobile() {
    const location = useLocation();

    const menuLinks = routes.sort((a: any, b: any) => a.order < b.order ? -1 : 1).map((route, index) => {
        return (
            <div key={index}>
            {
                route.icon ? 
                <li className={route.path === location.pathname ? 'active' : ''}>
                    <FontAwesomeIcon icon={route.icon} /> 
                    <Link to={route.path}>{route.title}</Link>
                </li>
                : null
            }
            </div>
        );
    });

  return (
    <div className='menu-mobile'>
        <ul>
            {menuLinks}
            <li className='logout'>
                <FontAwesomeIcon icon={faArrowRightFromBracket} /> 
                <Link to="/connexion">Déconnexion</Link>
            </li>
        </ul>
    </div>
  )
}

export default MenuMobile;