import * as React from 'react';

const ShowOldRatio = (data: any) => {
	return (
		<>
			<div className="old-ratio">
				{ 
					data.humanKeys ?
						Object.keys(data.humanKeys).map((key: any) => {
							if (data.selectedRatio[key] === "" || data.selectedRatio[key] === null) return;
							return (
								<>
									<div className="row"><strong className="key-key">{data.humanKeys[key]}</strong> {data.selectedRatio[key]}</div>
								</>
							)
						})

					: void(0)
			}
			</div>
		</>
	)
}

export default ShowOldRatio;