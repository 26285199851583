import * as React from 'react';
import { Link } from 'react-router-dom';
import {routes} from './utils/Routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartFlatbed, faCircleQuestion, faComputer, faHouse, faPenRuler, faPhone, faStar, faUsersLine } from "@fortawesome/free-solid-svg-icons";
import logo from '../resources/images/logo_PME.png';
import { useLocation } from "react-router-dom";
import { faArrowRightFromBracket, faDatabase, faPencil } from '@fortawesome/free-solid-svg-icons';
import { getUser } from './utils/Common';
import { baseUrlForEnvironment } from './utils/Axios';
import { getToken } from '../components/utils/Common';

function Menu() {

    const location = useLocation();
    const menuLinks = routes.sort((a: any, b: any) => a.order < b.order ? -1 : 1).map((route:any , index:any ) => {
        return (
            <div key={index}>
            {
                route.key !== "ratio" && route.key !== "employe" &&
                route.icon ? 
                <li className={route.path === location.pathname ? 'active' : ''}>
                    <Link to={route.path}>
                        <FontAwesomeIcon icon={route.icon} /> 
                        {route.title}
                    </Link>
                </li>
                : null
            }
            </div>
        );
    });

  return (
    <div className='menu'>
        <img className='menu__logo' src={logo} alt='Logo' />
        <h2>Bonjour</h2>
        <div className='user-info'>
            <p>{getUser()?.firstName} {getUser()?.lastName}</p>
        </div>
        <ul>
            {menuLinks}
            <li className='logout'>
            <Link to="/connexion">
                <FontAwesomeIcon icon={faArrowRightFromBracket} /> 
                Déconnexion
            </Link>
            </li>
        </ul>
    </div>
  )
}

export default Menu;