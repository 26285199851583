import * as React from 'react';
import { useState, useEffect } from 'react';
import { bdAxios } from '../components/utils/Axios';
import { parseJwt, getToken } from '../components/utils/Common';
import EditOffice from './forms/EditOffice';

function ShowOffice() {

	const [offices, setOffices] = useState<any>();
	const [branches, setBranches] = useState<any>();
	const [selectedOffice, setSelectedOffice] = useState<any>();
	const [selectedOfficeObjet, setSelectedOfficeObjet] = useState<any>();

	useEffect(() => {
		let token = (getToken() ? getToken() : '');
		if (token === null) {
			return;
		}

		setSelectedOffice(null);

		bdAxios.get('myoffices', {headers:{
			'Authorization': "Bearer " + token
		}}).then((r) => {
			const off: any = [];
			const branches: any = [];

			r.data.offices.map((office:any) => {
				if (office.post_parent !== 0) {
					branches.push(office);
				} else {
					off.push(office);
				}
			});

			setOffices(off);
			setBranches(branches);
		})
	}, []);

	const showOffice = (officeId:number) => {

		setSelectedOffice(officeId);
		let offObj: any = null;
		offices.map((office: any) => {
			if (office.ID === officeId) {
				offObj = office;
			}
		});

		setSelectedOfficeObjet(offObj);
	}

	const back = () => {
		setSelectedOffice(null);
	}

	return (
		<>
		{ selectedOffice ? 
			<>
				<a href="#" onClick={e => back()}>Retour</a><br/>
			</>
			:void(0)
		}
			{
				(!selectedOffice && offices && offices.length > 0) ?
				offices.map((office: any) => {
					return (
						<h5 key={office.ID} className="office-title" onClick={e => showOffice(office.ID)}>{office.post_title}</h5>
					)
				})
				: void(0)
			}

			{ selectedOffice ? 
				<>
					<EditOffice office={selectedOfficeObjet} />
				</>
				: void(0)
			}
		</>
	)
}

export default ShowOffice;