import * as React from 'react';
import { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import RatioForm from './forms/RatioForm';
import { bdAxios } from '../components/utils/Axios';
import { parseJwt, getToken } from '../components/utils/Common';
import Select from 'react-select'
import { useParams } from 'react-router-dom';

function Ratio() {
	const params = useParams();
	const [selectedRatio, setSelectedRatio] = useState<any>();


	useEffect(() => {
		let token = (getToken() ? getToken() : '');
		if (token === null) {
			return;
		}
		bdAxios.get('ratios/get?id='+params.id, {headers:{
			'Authorization': "Bearer " + token
		}}).then((r) => {
			let ratio = r.data.data.ratio;
			ratio.data = JSON.parse(ratio.data);
			setSelectedRatio(ratio);
		});
	}, []);

	 const download = () => {
	 	let token = (getToken() ? getToken() : '');
	 	if (token === null) {
	 		return;
	 	}
	 	bdAxios.post('ratios/export', {id: selectedRatio.ID}, {
	 		headers: {
				'Authorization': "Bearer " + token
			}
	 	}).then(r => {
	 		let a = document.createElement('a');
	 		a.setAttribute('href', r.data.excelData);
	 		a.setAttribute('download', selectedRatio.post_title+'.xls');
	 		document.querySelector("body")?.append(a);
	 		a.click();
	 		a.remove();
	 	}).catch(err => console.error(err));
	 }

	return (
		<Layout title="Accueil" classes="accueil-page">
			<div className="inner-page">
				<div className="content">
				{
					selectedRatio.isOldRatio ?
						selectedRatio.map((category: any) => {
							return (
								<>
									<h3>Ratio</h3>
									{ 
										category.map((field:any) => {
											return (
												<>
													<div className="label">{field.label} <span className="value">{field.value}</span></div>
												</>
											)
										})
									}
								</>
							)
						})
					: 
					<RatioForm selectedRatio={selectedRatio} />
				}
					<input type="button" onClick={download} defaultValue="Exporter" className="button" />
				</div>
			</div>
		</Layout>
	)
}

export default Ratio;
